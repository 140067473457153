@import 'src/base';

.rfid-card-creation-result-success {
    background: white;
    height: 100vh;
    display: flex;
    align-items: center;
    .rfid-card-creation-result-success-title {
        color: #15b9b0;
        font-size: 1.5rem;
    }
    .rfid-card-creation-result-success-subtitle {
        font-size: 1rem;
    }
    .rfid-card-creation-result-success-btns-item {
        margin-top: 3rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        height: auto;
        width: 100%;
    }
}
