@import 'src/base';

.lock-list {
    background: #f8f8fa;
    .lock-list-scrollcontainer {
        padding: 1rem;
        height: calc(100vh - 81px);
        overflow: auto;
    }
    .lock-list-card {
        border-radius: 8px;
        background: white;
    }
}
.lock-list {
    background: #f8f8fa;
    .lock-list-small-listitem {
        padding: 0.5rem;
    }
    .lock-list-scrollcontainer {
        padding: 0.5rem;
        height: calc(100vh - 65px);
        overflow: auto;
        .ant-col {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .ant-col {
        padding-left: 0;
        padding-right: 0;
    }
    .lock-list-scrollcontainer-spin {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 160px);
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.4);
        z-index: 1;
    }
    .lock-list-card-listitem {
        border-bottom: none;
        padding: 0.1rem;
        .lock-list-card {
            width: 100%;
            border-radius: 8px;
            background: white;
            .ant-card-body {
                padding: 1rem 1.5rem;
                .ant-typography {
                    width: 272px;
                    text-overflow: ellipsis;
                    font-size: 1rem;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }
    .lock-list-card-locks {
        width: 100%;
        border-radius: 8px;
        background: white;
        .ant-card-body {
            padding: 1rem 1.5rem;
            .ant-typography {
                width: 272px;
                text-overflow: ellipsis;
                font-size: 1rem;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
