@import 'src/base';

.rfid-card-creation-introduction {
    background: white;
    height: 100vh;
    display: flex;
    align-items: center;
    .rfid-card-creation-introduction-title {
        font-size: 1.5rem;
    }
    .rfid-card-creation-introduction-text {
        font-size: 1rem;
    }
    .rfid-card-creation-introduction-icon {
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
    }
    .rfid-card-creation-introduction-btn {
        margin-top: 3rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        height: auto;
        width: 50%;
    }
}
