@import 'src/base';

.passcode-details-header {
    background: #f8f8fa;    
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25098);
}
.passcode-details {
    min-height: 100vh;
    background: white;
    .passcode-details-divider {
        margin-top: 0;
    }
    .passcode-details-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        padding: 0 1rem;
        .passcode-details-container-dateTags {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            .passcode-details-container-tag {
                background: rgba(159, 151, 189, 0.1);
                border: none;
                border-radius: 6px;
                padding: 10px 10px;
                font-weight: bold;
                font-size: .875rem;
                margin: 0;
            }
        }
        .passcode-details-container-button {
            font-weight: bold;
            margin-top: 1rem;
            height: 2.5rem;
            font-size: 1rem;            
            border-width: 2px;
        }
        .passcode-details-container-button-delete{
            font-weight: bold;
            color: #F23D4F;
            height: 2.5rem;
            font-size: 1rem;
            border-width: 2px;
        }
        .passcode-details-container-label {
            font-size: 1rem;
            margin: 0;
        }
        .spacing {
            letter-spacing: 3px;
        }
    }
    .loading-spiner {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
}
