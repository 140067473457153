@import 'src/base';
.passcode-creation-header {
    padding: 1rem 1.5rem;
    margin: 0;
}
.passcode-creation {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    * {
        font-size: 1rem;
    }
    .passcode-creation-divider {
        margin: 0;
    }
    .passcode-creation-picker-item {
        width: 100%;
        border-radius: 0.5rem;
        line-height: 2rem;
        height: 3rem;
    }
    .passcode-creation-form {
        padding: 1.5rem;
        font-size: 1rem;
        .passcode-creation-form-icon {
            svg {
                color: #15b9b0;
                font-size: 4rem;
            }
        }
        .passcode-creation-form-select {
            height: 48px;
            line-height: 48px;
            align-items: center;
            .ant-select-selector {
                border-radius: 0.5rem;
                align-items: center;
                height: 48px;
                line-height: 48px;
                .ant-select-selection-item {
                    line-height: 48px;
                    align-self: baseline;
                    height: 3rem;
                }
            }
        }
        .passcode-creation-form-btn {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            height: auto;
            width: auto;
        }
    }

    .passcode-creation-picker {
        .passcode-creation-picker-item {
            display: flex;
            justify-content: space-between;
        }
    }
    .loading-spiner {
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: red;
        padding: 30px 50px;
        border-radius: 4px;
        min-height: 100vh;
    }
}
