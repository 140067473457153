@import 'src/base';

.error-component {
    min-height: 100vh;
    .error-component-icon {
        svg {
            color: gray;
        }
    }
    .error-component-button {
        height: 2.5rem;
        font-size: 1rem;
    }
}
