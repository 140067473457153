@import 'src/base';

.rfid-card-creation-result-info {
    background: white;
    height: 100vh;
    display: flex;
    align-items: center;
    .rfid-card-creation-result-info-icon {
        svg {
            color: #550093;
            font-size: 5rem;
            transform: rotate(180deg);
        }
    }
    .rfid-card-creation-result-info-title {
        color: #550093;
        font-size: 1.5rem;
    }
    .rfid-card-creation-result-info-subtitle {
        font-size: 1rem;
    }
    .rfid-card-creation-result-info-btns-item {
        margin-top: 3rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        height: auto;
        width: 100%;
    }
}
