@import 'src/base';

.keys-detail {
    min-height: 100vh;
    background: white;
    .keys-details-header {
        background: #f8f8fa;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25098);
    }
    .keys-details-schedule-tag {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        min-width: 100%;
        padding: 0.8rem 0.5rem;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-radius: 8px;
    }
    .keys-details-container {
        margin-top: 1rem;
        padding: 0 1rem;
        .keys-details-container-tags {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .keys-details-tag {
                width: fit-content;
                padding: 10px 10px;
                font-size: 12px;
                border-radius: 6px;
                background: rgba(159, 151, 189, 0.1);
                border: none;
                color: #777;
                font-weight: bold;
                margin-bottom: 4px;
                font-size: 0.875rem;
            }
        }
        .keys-details-container-button {
            font-weight: bold;
            color: #f23d4f;
            height: 2.5rem;
            font-size: 1rem;
            border-width: 2px;
        }
        .keys-details-finish-config-button {
            font-weight: bold;
            color: #f7b28b;
            height: 2.5rem;
            font-size: 1rem;
            border-width: 2px;
        }
        .keys-details-container-label {
            font-size: 1rem;
            margin: 0;
        }
        .spacing {
            letter-spacing: 3px;
        }
    }
    .loading-spiner {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
}
