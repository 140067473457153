@import 'src/base';

.rfid-card-details-header {
    background: #f8f8fa;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25098);
}
.rfid-card-detail {
    min-height: 100vh;
    background: white;
     .rfid-card-details-schedule-tag {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        min-width: 100%;
        padding: .8rem .5rem;
        font-size: 1rem;
        font-weight: bold;
        border: none;
        border-radius: 8px;
    }
    .rfid-card-details-container {
        margin-top: 1rem;
        padding: 0 1rem;
        .rfid-card-details-container-tags {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            
            .rfid-card-details-tag {
                width: fit-content;
                padding: 10px 10px;
                font-size: 12px;
                border-radius: 6px;
                background: rgba(159, 151, 189, 0.1);
                border: none;
                color: #777;
                font-weight: bold;
                margin-bottom: 4px;
                font-size: .875rem;
            }
        }
        .rfid-card-details-container-button {
            font-weight: bold;
            color: #F23D4F;
            height: 2.5rem;
            font-size: 1rem;
            border-width: 2px;
        }
        .rfid-card-details-finish-config-button{
            font-weight: bold;
            color: #F7B28B;
            height: 2.5rem;
            font-size: 1rem;
            border-width: 2px;
        }
        .rfid-card-details-container-label {
            font-size: 1rem;
            margin: 0;
        }
        .spacing {
            letter-spacing: 3px;
        }
    }
    .loading-spiner {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
    .rfid-card-detail-deleted-card{
        background: white;
        height: 100vh;
        display: flex;
        align-items: center;
        .rfid-card-detail-deleted-card-title{
            color:  #F23D4FCC;
            font-size: 1.5rem;
        }
    }
}