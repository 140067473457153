@import 'src/base';

.keys-list {
    background-color: #fff;
    // background-color: rgb(255, 194, 194);
    // max-height: 100vh;
    // height: calc(100vh - 130px);
 
    .keys-list-divider {
        margin: 0;
    }
    .keys-list-actions {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        .keys-list-actions-search {
            .ant-input-affix-wrapper {
                border-radius: 8px;
            }
            input {
                border-radius: 8px;
                height: 38px;
            }
            button {
                display: none;
            }
        }
        .keys-list-actions-givekeybtn {
            border-radius: 8px;
            font-weight: bold;
            font-size: 2rem;
            background: #553bbd;
            height: 48px;
            width: 48px;
            min-width: 48px;
            padding: 0;
            svg {
                height: 30px;
                width: 30px;
            }
        }
        .keys-list-actions-sortbtn {
            border-radius: 8px;
            font-weight: bold;
            font-size: 1rem;
            background: #fff;
            height: 48px;
            width: 48px;
            min-width: 48px;
            padding: 0;
            svg {
                height: 30px;
                width: 30px;
            }
        }
        .keys-list-actions-select {
            .ant-select-selector {
                border: 1px solid #d9d9d9;
                height: 48px;
                line-height: 48px;
                border-radius: 8px;
                width: 230px;
                .ant-select-selection-item {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 46px;
                    padding: 0;
                    height: 48px;
                    align-self: baseline;
                }
            }
            .ant-select-arrow {
                color: #222;
            }
        }
    }
    .keys-list-scrollcontainer {
        padding: .4rem .8rem;
        // height: calc(100vh - 193px);
        overflow: auto;
        .keys-list-scrollcontainer-tag {
            padding: 1px 5px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 4px;
            background: white;
            border: 1px solid rgba(0, 0, 0, 0.3);
            color: rgba(0, 0, 0, 0.3);
            margin-bottom: 4px;
        }
    }
}
