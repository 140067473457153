@import 'src/base';

.rfid-card-list {
    background-color: #fff;
    // min-height: 100vh;
    .rfid-card-list-scrollcontainer {
        padding: .4rem .8rem;
        // height: calc(100vh - 193px);
        overflow: auto;
        .rfid-card-list-scrollcontainer-tag {
            padding: 1px 5px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 4px;
            background: white;
            border: 1px solid rgba(0, 0, 0, 0.3);
            color: rgba(0, 0, 0, 0.3);
            margin-bottom: 4px;
        }
    }
}
