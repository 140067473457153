@import 'src/base';

.search-header {
    background: white;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .search-header-back {
        width: auto;
        height: 100%;
    }
    .search-header-search {
        .ant-input-affix-wrapper {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            border-radius: 8px;
        }
        input {
            height: 38px;
        }
        button {
            display: none;
        }
    }
}
