@import 'src/base';

.rfid-card-delete-error-lock {
    background: white;
    height: 100vh;
    display: flex;
    align-items: center;
    .rfid-card-delete-error-lock-icon {
        svg {
            color: #f8bf9f;
            font-size: 5rem;
        }
    }
    .rfid-card-delete-error-lock-title {
        color: #f8bf9f;
        font-size: 1.5rem;
    }
    .rfid-card-delete-error-lock-subtitle {
        font-size: 1rem;
    }
    .rfid-card-delete-error-lock-btns {
        .rfid-card-delete-error-lock-btns-item {
            margin-top: 3rem;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            height: auto;
            width: 50%;
        }
    }
}
