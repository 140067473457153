@import 'src/base';

.main {
    min-height: 100vh;
    .content-root {
        background-color: $background-content;
        // padding: 2rem;
    }

    .footer-root {
        background-color: $white;
        padding: 0;
        font-size: 12pt;
        text-align: center;
        padding: 2rem 2.5rem;
        .footer-link {
            color: $primary-color;
            font-weight: 600;
        }
    }
    @media screen and (max-width: $breakpoint-md) {
        .content-root {
            // padding: 0.5rem;
            width: 100%;
        }
    }
}
