@import 'src/base';

.create-key-summary {
    background: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .create-key-summary-subtitle {
        font-size: 1rem;
    }
    .create-key-summary-btn {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        height: auto;
        width: 100%;
    }
    .create-key-summary-btn {
        margin-top: 3rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        height: auto;
        width: 100%;
    }
}
