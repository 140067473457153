@import 'src/base';

.site-list {
    background: #f8f8fa;
    .site-list-title-watchmore {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        padding: 0 1rem;
        .site-list-title-watchmore-text {
            padding: 0 1rem;
            font-size: 1rem;
        }
        .site-list-title-watchmore-line {
            height: 1px;
            background: rgba(0, 0, 0, 0.06);
            width: 100%;
        }
    }
    .site-list-collapse-panel {
        .ant-collapse-content-box {
            position: relative;
            padding: 0;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            .ant-col {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .ant-collapse-header {
            padding: 8px 16px;
            padding-right: 40px;
            background: #eaeaf0;
            border-top: rgba(0, 0, 0, 0.06) solid 1px;
            border-bottom: rgba(0, 0, 0, 0.06) solid 1px;
        }
    }
    .site-list-collapse-divider {
        margin: 0;
    }
    .site-list-scrollcontainer {
        padding: 0.5rem;
        height: calc(100vh - 81px);
        overflow: auto;
    }
    .site-list-small-listitem {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        .site-list-title-watchmore-button {
            width: 30%;
            margin-left: auto;
            margin-top: 0.5rem;
        }
    }
    .site-list-scrollcontainer-collapse {
        padding: 0.5rem;
        max-height: calc(100vh - 160px);
        overflow: auto;
        .ant-col {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .ant-col {
        padding-left: 0;
        padding-right: 0;
    }
    .site-list-scrollcontainer-spin {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: calc(100vh - 160px);
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.4);
        z-index: 1;
    }
    .site-list-scrollcontainer-spin-fullscreen {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 160px);
        width: 100%;
    }
    .site-list-scrollcontainer-empty {
        margin-top: 1rem;
    }
    .site-list-card-listitem {
        border-bottom: none;
        padding: 0.1rem;
        .site-list-card {
            width: 100%;
            border-radius: 8px;
            background: white;
            .ant-card-body {
                padding: 1rem 1.5rem;
                .ant-typography {
                    width: 272px;
                    text-overflow: ellipsis;
                    font-size: 1rem;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }
    .site-list-card-sites {
        width: 100%;
        border-radius: 8px;
        background: white;
        .ant-card-body {
            padding: 1rem 1.5rem;
            .ant-typography {
                width: 272px;
                text-overflow: ellipsis;
                font-size: 1rem;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}
