@import 'src/base';

.notification-list {
    min-height: 100vh;
    background: white;
    .list-scrollcontainer {
        height: 100vh;
        overflow-y: auto;
    }
    .notification-list {
        .notification-item {
            padding: 8px 8px;
            border: none;
            .ant-list-item-meta-avatar {
                margin-right: 0.5rem;
                display: flex;
                align-self: center;
            }
            .notification-item-avatar {
                height: 2rem;
                width: 2rem;
            }
            .notification-item-title {
                color: #555;
                padding: 0;
                margin: 0;
            }
            .notification-item-content {
                color: #777;
            }
        }
    }
}
