@import 'src/base';

.create-key-form {
    padding: 1rem;

    label {
        font-size: 1rem;
    }

    .loading-spiner {
        margin: 20px 0;
        margin-bottom: 20px;
        padding: 30px 50px;
        text-align: center;
        border-radius: 4px;
    }
    // background-color: red;
    .lock-select {
        height: 48px;
        line-height: 48px;
        align-items: center;
        .ant-select-selector {
            border-radius: 0.5rem;
            align-items: center;
            height: 48px;
            line-height: 48px;
            .ant-select-selection-item {
                line-height: 48px;
                align-self: baseline;
            }
        }
    }

    .create-key-form-btn {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        height: auto;
        width: auto;
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .keys-dashboard {
        width: 100%;
        margin: 0;
        padding: 1rem;

        .list-info {
            padding: 1rem;
        }
        .image {
            width: 100%;
        }
    }
}
