@import 'src/base';

.form-duration-picker {
    .form-duration-picker-radio-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        .ant-radio-button-wrapper {
            border-radius: 8px;
            border-left-width: 1px;
            padding: 0.3rem 1rem;
            height: fit-content;
            width: fit-content;
        }
        label.ant-radio-button-wrapper::before {
            display: none;
        }
    }
    .form-duration-picker-text {
        margin-top: 10px;
    }
}
.form-duration-picker-modal-arrow-btn {
    padding: 0 0.2rem;
    background-color: transparent;
}
